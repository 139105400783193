<template>
  <div v-if="dialog && users">
    <v-dialog
      :value="dialog"
      max-width="65%"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <div class="modal-header">
          <h5 class="modal-title" id="formModal">
            {{
              this.reportUrl == "master"
                ? "List Senior Statement"
                : this.reportUrl == "agent"
                ? "List Master Statement"
                : this.reportUrl == "player"
                ? "List Agent Statement"
                : this.reportUrl == "playerDetail"
                ? "List Player Statement"
                : "Loading ..."
            }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="$emit('onCloseDialog')"
          >
            <span @click="$emit('onCloseDialog')" aria-hidden="true">×</span>
          </button>
        </div>
        <v-card-text>
          <br />
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fas fa-search"></i>
              </div>
            </div>
            <input v-model="v_search" type="text" class="form-control mr-1" />
            <button
              class="btn btn-primary"
              color="green darken-1"
              depressed
              @click="search"
            >
              SEARCH
            </button>
          </div>
          <br />
          <div class="card">
            <div class="card-body">
              <div class="table-responsive" style="text-align: center">
                <v-data-table
                  style="font-family: 'khmer mef1'"
                  class=" table table-striped"
                  id="table-1"
                  item-key="_id"
                  :headers="headers"
                  :footer-props="footerProps"
                  :items="users ? users.object : []"
                  :server-items-length="users ? users.objectCount.count : null"
                  :sort-desc.sync="dataRequest.descendingFormat"
                  hide-default-header
                  :sort-by.sync="dataRequest.sortByFormat"
                  :page.sync="dataRequest.page"
                  :items-per-page.sync="dataRequest.rowsPerPage"
                  @update:page="$emit('getData4')"
                  @update:items-per-page="$emit('getData4')"
                  @update:sort-desc="$emit('getData4')"
                  mobile-breakpoint="0"
                >
                  <template v-slot:header="{ props }">
                    <th v-for="head in props.headers" :key="head._id">
                      {{ head.text }}
                    </th>
                  </template>
                  <template v-slot:[`item`]="{ item }">
                    <tr>
                      <td>{{ users.object.indexOf(item) + 1 }}</td>
                      <td>
                        <v-icon
                          color="green"
                          small
                          class="mr-2"
                          @click="getUserChild5(item)"
                          >{{ reportUrl ? "mdi-eye" : "mdi-eye-off" }}
                        </v-icon>
                      </td>
                      <td>{{ item._id.app_id }}</td>
                      <td v-if="item._id.com">
                        {{
                          item._id.com &&
                          (user.role_name == "DEVELOPER" ||
                            user.role_name == "MANAGER" ||
                            user.role_name == "COMPANY" ||
                            user.role_name == "SUB-COMPANY")
                            ? item._id.com
                            : "---"
                        }}
                      </td>
                      <td v-if="item._id.admin">
                        {{
                          item._id.admin &&
                          (user.role_name == "DEVELOPER" ||
                            user.role_name == "MANAGER" ||
                            user.role_name == "COMPANY" ||
                            user.role_name == "SUB-COMPANY" ||
                            user.role_name == "SUB-SENIOR-R" ||
                            user.role_name == "SENIOR-R")
                            ? item._id.admin
                            : "---"
                        }}
                      </td>
                      <td v-if="item._id.master">
                        {{
                          item._id.master &&
                          (user.role_name == "DEVELOPER" ||
                            user.role_name == "MANAGER" ||
                            user.role_name == "COMPANY" ||
                            user.role_name == "SUB-COMPANY" ||
                            user.role_name == "SUB-SENIOR-R" ||
                            user.role_name == "SENIOR-R" ||
                            user.role_name == "SUB-MASTER-R" ||
                            user.role_name == "MASTER-R")
                            ? item._id.master
                            : "---"
                        }}
                      </td>
                      <td v-if="item._id.agent">{{ item._id.agent }}</td>
                      <td v-if="item._id.player">{{ item._id.player }}</td>
                      <td>{{ currencyFormat(item._id.player_balance) }}</td>
                      <td class="amount-bet-color">
                        {{ currencyFormat(item.amount) }}
                      </td>
                      <td
                        :class="
                          item.amount_win < 0 ? 'lose-color' : 'win-color'
                        "
                      >
                        {{ currencyFormat(item.amount_win) }}
                      </td>
                    </tr>
                  </template>
                  <template slot="body.append">
                    <tr style="background-color: #faffe5; font-weight: bold;">
                    <td
                      :colspan="headers ? headers.length - 2 : 2"
                      class="text-xs-center pl-4 py-2"
                    >
                      {{ $t("title.total") }}
                    </td>
                    <td class="total-style">
                      {{
                        users.objectCount.amount
                          ? currencyFormat(users.objectCount.amount)
                          : 0
                      }}
                    </td>
                    <td
                      class="total-style font-weight-bold"
                      :style="
                        users.objectCount.amount_win < 0
                          ? 'color:#BA2D4A'
                          : 'color:#39afd6'
                      "
                    >
                      {{
                        users.objectCount.amount_win
                          ? currencyFormat(users.objectCount.amount_win)
                          : 0
                      }}
                    </td>
                    </tr>
                  </template>
                </v-data-table>
              </div>
              <!-- <div class="text-center pt-2">
                <v-pagination
                  v-model="data5.page"
                  :length="
                    users
                      ? users.objectCount.count % data5.rowsPerPage != 0
                        ? users.objectCount.count / data5.rowsPerPage + 1
                        : users.objectCount.count / data5.rowsPerPage
                      : 0
                  "
                ></v-pagination>
                <v-text-field
                  :value="data5.rowsPerPage"
                  label="Items per page"
                  type="number"
                  min="-1"
                  max="15"
                  @input="data5.rowsPerPage = parseInt($event, 10)"
                ></v-text-field>
              </div> -->
              
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <statement-list5
      :darkmode="darkmode"
      :dialog="dialogListUser"
      :users="userChild5"
      :data-request="data5"
      v-on:getData5="getUserChild5(item)"
      :date="date"
      :user="user"
      @onSearch="onSearch"
      @onCloseDialog="dialogListUser = !dialogListUser"
    />
    <loader
      v-if="this.loaderD4 == true"
      object="#ff9633"
      color1="#ffffff"
      color2="#17fd3d"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      name="circular"
    ></loader>
  </div>
</template>

<script>
import headerPlayerDetail from "../../_api/headerPlayerDetail";
import headerPlayer from "../../_api/headerPlayer";
import StatementList5 from "./Dialog5";
import moment from "moment";
import Vue from "vue";

export default {
  components: { StatementList5 },
  props: ["darkmode", "dialog", "users", "dataRequest", "user", "date"],
  data: () => {
    return {
      dialogListUser: false,
      userChild5: [],
      footerProps: {
        "items-per-page-options": [10, 20, 50, 100, 200],
      },
      item: null,
      data5: {
        descendingFormat: [true],
        descending: true,
        sortByFormat: ["_id"],
        sortBy: "_id",
        page: 1,
        rowsPerPage: 10,
        fields: [],
        search: "",
        channelType: 0,
        startDate: new Date().toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),
      },
      v_startDate: false,
      v_endDate: false,
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      v_search: "",
      params: "",
      loaderD4: false,
    };
  },
  computed: {
    headers: function () {
      if (
        this.user.role_name == "DEVELOPER" ||
        this.user.role_name == "MANAGER" ||
        this.user.role_name == "SPECIAL-MANAGER"
      ) {
        return headerPlayer;
      } else if (
        this.user.role_name == "COMPANY" ||
        this.user.role_name == "SUB-COMPANY"
      ) {
        return headerPlayerDetail;
      } else return [];
    },
    reportUrl: function () {
      if (this.user) {
        if (
          this.user.role_name == "DEVELOPER" ||
          this.user.role_name == "MANAGER" ||
          this.user.role_name == "SPECIAL-MANAGER"
        )
          return "playerDetail";
        else return [];
      } else return [];
    },
  },
  methods: {
    getUserChild5(item, search = "") {
      this.loaderD4 = true;
      if (search) {
        this.data5.search = search.v_search ? search.v_search : '';
        this.data5.channelType = search.channel_type ?search.channel_type : 0;
      }
      if (this.date) {
        this.data5.startDate = moment(this.date.startDate).format("YYYY-MM-DD") + "T00:00:00";
        this.data5.endDate = moment(this.date.endDate).format("YYYY-MM-DD") + "T23:59:59";
      }
      this.item = item;
      this.data5.fields = this.calFields();
      this.data5.sortByFormat.length < 1
        ? (this.data5.sortBy = "_id")
        : (this.data5.sortBy = this.data5.sortByFormat[0]);
      this.data5.descendingFormat.length > 0
        ? (this.data5.descending = this.data5.descendingFormat[0])
        : (this.data5.descending = true);
      Vue.$cookies.set("currency-type", 2, 0);
      console.log(this.data5);
      this.$request
        .post({
          url: "statementCurrency/" + this.reportUrl + "/" + this.calID(item),
          data: this.data5,
        })
        .then((res) => {
          if (res.data.code) {
            this.loaderD4 = false;
            this.dialogListUser = true;
            this.userChild5 = res.data.data;
          }
        });
    },
    onSearch(search) {
      this.getUserChild5(this.item, search);
    },
    search() {
      this.$emit("onSearch", this.v_search);
    },
    currencyFormat(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString("en");
    },
    calID(item) {
      if (
        this.user.role_name == "DEVELOPER" ||
        this.user.role_name == "MANAGER" ||
        this.user.role_name == "SPECIAL-MANAGER"
      ) {
        return item._id.player_id;
      } else return this.user.role_name;
    },
    calFields() {
      if (
        this.user.role_name == "DEVELOPER" ||
        this.user.role_name == "MANAGER" ||
        this.user.role_name == "SPECIAL-MANAGER"
      ) {
        return [
          "app_id",
          "public_ip",
          "date",
          "amount",
          "amount_win",
          "co_type_of_betting.name",
          "fight_no",
        ];
      } else return this.user.role_name;
    },
  },
};
</script>

<style scoped>
table {
  font-family: "khmer mef1" !important;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border: 0px !important;
}
.btn-custom {
  position: relative;
  top: 10px;
}

.amount-bet-color {
  color: goldenrod !important;
}

.win-color {
  color: rgb(57, 175, 214);
  font-weight: bold;
}

.lose-color {
  color: #ba2d4a;
  font-weight: bold;
}
</style>
